var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "evotor-sidebar",
      "title": "Эвотор",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    on: {
      "shown": function ($event) {
        return _vm.setIntegration('evotor');
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Эвотор "), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.updateIntegration
          }
        }, [_vm._v(" Сохранить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v(" Эвотор – это умные онлайн-кассы, созданные на базе планшета, имеющие дисплей и простой интерфейс управления. Эвотор позволяет начать продажи, купив лишь одно устройство вместо целого арсенала оборудования. ")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")], 1)]), _c('div', {
    staticClass: "sidebar-block token"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(" Токен "), _c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-gray.svg",
      "alt": ""
    }
  }), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addToken.apply(null, arguments);
      }
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Номер токена"
    },
    model: {
      value: _vm.token,
      callback: function ($$v) {
        _vm.token = $$v;
      },
      expression: "token"
    }
  })], 1)], 1), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "primary",
      "disabled": _vm.connect_is_disabled
    },
    on: {
      "click": _vm.addToken
    }
  }, [_vm._v(" Подключить ")])], 1), _c('b-collapse', {
    attrs: {
      "id": "my-collapse-id"
    },
    model: {
      value: _vm.collapse_visible,
      callback: function ($$v) {
        _vm.collapse_visible = $$v;
      },
      expression: "collapse_visible"
    }
  }, [_c('div', {
    staticClass: "sidebar-block"
  }, [_c('div', {
    staticClass: "block-title"
  }, [_vm._v("Сопоставьте юрлица с местами хранения")]), _c('div', {
    staticClass: "b-select"
  }, [_c('div', {
    staticClass: "b-select__title"
  }, [_c('div', {
    staticClass: "item"
  }, [_vm._v("Магазин Энтерсайт")]), _c('div', {
    staticClass: "item"
  }, [_vm._v("Магазин Эвотор")])]), _vm._l(_vm.branch_mapping, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "b-select__item"
    }, [_c('i-select', {
      attrs: {
        "filterable": ""
      },
      model: {
        value: item.int_id,
        callback: function ($$v) {
          _vm.$set(item, "int_id", $$v);
        },
        expression: "item.int_id"
      }
    }, _vm._l(_vm.branch_list, function (el, index) {
      return _c('i-option', {
        key: index,
        attrs: {
          "value": el.id
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")]);
    }), 1), _c('img', {
      staticClass: "cursor",
      attrs: {
        "src": "/img/integrations/arrows-left-right.svg",
        "alt": ""
      }
    }), _c('i-select', {
      attrs: {
        "filterable": ""
      },
      on: {
        "on-change": _vm.addExtBranch
      },
      model: {
        value: item.ext_id,
        callback: function ($$v) {
          _vm.$set(item, "ext_id", $$v);
        },
        expression: "item.ext_id"
      }
    }, _vm._l(_vm.evotor_store_list, function (el, index) {
      return _c('i-option', {
        key: index,
        attrs: {
          "value": el.id
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")]);
    }), 1)], 1);
  })], 2)]), _c('div', {
    staticClass: "sidebar-block"
  }, [_c('div', {
    staticClass: "block-title"
  }, [_vm._v("Сопоставьте роли пользователей")]), _c('div', {
    staticClass: "b-select"
  }, [_c('div', {
    staticClass: "b-select__title"
  }, [_c('div', {
    staticClass: "item"
  }, [_vm._v("Роли пользователей Энтерсайт")]), _c('div', {
    staticClass: "item"
  }, [_vm._v("Роли пользователей Эвотор")])]), _vm._l(_vm.role_mapping, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "b-select__item"
    }, [_c('i-select', {
      attrs: {
        "filterable": ""
      },
      model: {
        value: item.int_id,
        callback: function ($$v) {
          _vm.$set(item, "int_id", $$v);
        },
        expression: "item.int_id"
      }
    }, _vm._l(_vm.role_list, function (el, index) {
      return _c('i-option', {
        key: index,
        attrs: {
          "value": el.id
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")]);
    }), 1), _c('img', {
      staticClass: "cursor",
      attrs: {
        "src": "/img/integrations/arrows-left-right.svg",
        "alt": ""
      }
    }), _c('i-select', {
      attrs: {
        "filterable": ""
      },
      model: {
        value: item.ext_id,
        callback: function ($$v) {
          _vm.$set(item, "ext_id", $$v);
        },
        expression: "item.ext_id"
      }
    }, _vm._l(_vm.evotor_role_list, function (el, index) {
      return _c('i-option', {
        key: index,
        attrs: {
          "value": el.id
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")]);
    }), 1)], 1);
  })], 2)]), _c('div', {
    staticClass: "sidebar-block"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isIntegrating
    }
  }, [_c('div', {
    staticClass: "block-title"
  }, [_vm._v("Желаете импортировать товары из кассовой системы Эвотор?")]), _vm.check_branch_mapping ? _c('div', [_c('p', [_vm._v(" Все группы товаров, товары и цены, находящиеся в кассовой системы Эвотор будут импортированы в систему Энтерсайт ")]), _c('a', {
    on: {
      "click": _vm.uploadClassif
    }
  }, [_vm._v("Импортировать данные")])]) : _c('div', [_c('p', [_vm._v("Чтобы импортровать данные сопоставьте и сохраните юрлица с местами хранения")])])])], 1), _c('div', {
    staticClass: "sidebar-block"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isIntegrating
    }
  }, [_c('div', {
    staticClass: "block-title"
  }, [_vm._v("Желаете экспортировать товары в кассовую систему Эвотор?")]), _vm.check_branch_mapping ? _c('div', [_c('p', [_vm._v(" Все группы товаров, товары и цены, находящиеся в кассовой системе Энтерсайт будут экспортированы в систему Эвотор ")]), _c('a', {
    on: {
      "click": _vm.exportClassif
    }
  }, [_vm._v("Экспортированы данные")])]) : _c('div', [_c('p', [_vm._v("Чтобы экспортированы данные сопоставьте и сохраните юрлица с местами хранения")])])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }