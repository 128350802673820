var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "crptech-inner"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "crptech-sidebar",
      "title": "Честный знак",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    on: {
      "shown": _vm.getCerts
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Честный знак "), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, {
      key: "footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary",
            "disabled": !_vm.selectedCert || !_vm.selectedBranch || _vm.inn_error
          },
          on: {
            "click": _vm.updateIntegration
          }
        }, [_vm._v(" Сохранить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v(" Единая национальная система цифровой маркировки и прослеживания товаров. Нанесение цифровой маркировки позволяет получить всю информацию о товаре: название предприятия-изготовителя, место, дату и время производства или продажи, срок годности, артикул, номер стандарта. Данные хранятся в государственной информационной системе. ")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")], 1)]), _c('div', {
    staticClass: "sidebar-block token"
  }, [_c('div', {
    staticClass: "text mb-2"
  }, [_vm._v(" Сертификат ")]), _c('i-select', {
    staticStyle: {
      "max-width": "630px"
    },
    attrs: {
      "label-in-value": true
    },
    on: {
      "on-change": _vm.clearBranch
    },
    model: {
      value: _vm.selectedCert,
      callback: function ($$v) {
        _vm.selectedCert = $$v;
      },
      expression: "selectedCert"
    }
  }, _vm._l(_vm.certs, function (option) {
    return _c('i-option', {
      key: option.thumbprint,
      staticStyle: {
        "max-width": "630px"
      },
      attrs: {
        "label": option.label,
        "value": option.thumbprint
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column",
        "max-width": "630px"
      }
    }, [_c('span', {
      staticClass: "mb-3",
      staticStyle: {
        "white-space": "pre-line"
      }
    }, [_vm._v(_vm._s(option.subjectName))]), _c('span', {
      style: new Date(option.validTo) < new Date() ? {
        color: '#E53835'
      } : {
        color: '#00CB91'
      }
    }, [_vm._v(" Срок действия: " + _vm._s(_vm.formatDate(option.validTo)))])])]);
  }), 1), _c('div', {
    staticClass: "text mb-2"
  }, [_vm._v("Магазин")]), _c('i-select', {
    staticStyle: {
      "max-width": "630px"
    },
    attrs: {
      "label-in-value": true
    },
    on: {
      "on-change": _vm.clearEntity
    },
    model: {
      value: _vm.selectedBranch,
      callback: function ($$v) {
        _vm.selectedBranch = $$v;
      },
      expression: "selectedBranch"
    }
  }, _vm._l(_vm.branches, function (option) {
    return _c('i-option', {
      key: option.id,
      staticStyle: {
        "max-width": "630px"
      },
      attrs: {
        "value": option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1), _c('div', {
    staticClass: "text mb-2"
  }, [_vm._v("Юр.лицо")]), _c('i-select', {
    staticStyle: {
      "max-width": "630px"
    },
    attrs: {
      "label-in-value": true
    },
    model: {
      value: _vm.selectedEntity,
      callback: function ($$v) {
        _vm.selectedEntity = $$v;
      },
      expression: "selectedEntity"
    }
  }, _vm._l(_vm.entities, function (option) {
    return _c('i-option', {
      key: option.id,
      staticStyle: {
        "max-width": "630px"
      },
      attrs: {
        "value": option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }