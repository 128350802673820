<template>
  <section id="crptech-inner">
    <b-sidebar
      id="crptech-sidebar"
      title="Честный знак"
      right
      backdrop
      shadow
      @shown="getCerts"
    >
      <template #header="{ hide }">
        Честный знак
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>

      <div class="sidebar-block info">
        <p>
          Единая национальная система цифровой маркировки и прослеживания товаров. Нанесение цифровой маркировки
          позволяет получить всю информацию о товаре: название предприятия-изготовителя, место, дату и время
          производства или продажи, срок годности, артикул, номер стандарта. Данные хранятся в государственной
          информационной системе.
        </p>
        <div class="info__detail">
          <img
            src="/img/integrations/icon-info-blue.svg"
            alt=""
          />
          <router-link to="#">Подробная справка</router-link>
          по интеграции
        </div>
      </div>
      <div class="sidebar-block token">
        <div class="text mb-2">
          Сертификат

          <!--          <img src="/img/integrations/icon-info-gray.svg" alt="" />-->
          <!--          <b-form @submit.prevent="addToken">-->
          <!--            <b-form-input-->
          <!--              v-model="token"-->
          <!--              type="text"-->
          <!--              placeholder="Номер токена"-->
          <!--            />-->
          <!--          </b-form>-->
        </div>
        <i-select
          v-model="selectedCert"
          style="max-width: 630px"
          :label-in-value="true"
          @on-change="clearBranch"
        >
          <i-option
            v-for="option of certs"
            :key="option.thumbprint"
            style="max-width: 630px"
            :label="option.label"
            :value="option.thumbprint"
          >
            <div style="display: flex; flex-direction: column; max-width: 630px">
              <span
                class="mb-3"
                style="white-space: pre-line"
                >{{ option.subjectName }}</span
              >
              <span :style="new Date(option.validTo) < new Date() ? { color: '#E53835' } : { color: '#00CB91' }">
                Срок действия: {{ formatDate(option.validTo) }}</span
              >
            </div>
          </i-option>
        </i-select>
        <div class="text mb-2">Магазин</div>
        <i-select
          v-model="selectedBranch"
          style="max-width: 630px"
          :label-in-value="true"
          @on-change="clearEntity"
        >
          <i-option
            v-for="option of branches"
            :key="option.id"
            style="max-width: 630px"
            :value="option.id"
          >
            {{ option.name }}
          </i-option>
        </i-select>
        <div class="text mb-2">Юр.лицо</div>
        <i-select
          v-model="selectedEntity"
          style="max-width: 630px"
          :label-in-value="true"
        >
          <i-option
            v-for="option of entities"
            :key="option.id"
            style="max-width: 630px"
            :value="option.id"
          >
            {{ option.name }}
          </i-option>
        </i-select>
      </div>
      <template #footer="{ hide }">
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor"
            :disabled="!selectedCert || !selectedBranch || inn_error"
            @click="updateIntegration"
          >
            Сохранить
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { getUserCertificates } from 'crypto-pro'

  export default {
    name: 'CrpTech',
    props: {
      crp_tech: {
        type: Object,
        default: () => {}
      }
    },
    apollo: {
      Branches: {
        query: require('../gql/getBranches.gql'),
        result({ data }) {
          this.branches = data?.Branches || []
        }
      }
    },
    data() {
      return {
        certs: [],
        branches: [],
        selectedCert: '',
        selectedBranch: '',
        selectedEntity: ''
      }
    },
    computed: {
      entities() {
        return this.branches.find((el) => el.id === this.selectedBranch)?.entities ?? []
      },
      inn_error() {
        const cert = this.certs?.find((el) => el.thumbprint === this.selectedCert)
        const entity = this.entities?.find((el) => el.id === this.selectedEntity)

        if (!cert || !entity) return true

        return !cert.subjectName.includes(entity.inn)
      }
    },
    async mounted() {},
    methods: {
      updateIntegration() {
        this.$emit('update-integration', {
          thumbprint: this.selectedCert,
          branch: this.selectedBranch,
          entity: this.selectedEntity
        })
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      clearEntity() {
        this.selectedEntity = ''
      },
      clearBranch() {
        this.selectedBranch = ''
      },
      async getCerts() {
        this.certs = await getUserCertificates()
        this.certs = this.certs.map((el) => {
          return {
            ...el,
            label: el.subjectName
          }
        })
        this.selectedCert = this.crp_tech?.items?.[0]?.login ?? ''
        this.selectedBranch = this.crp_tech?.items?.[0]?.branch ?? ''
        this.selectedEntity = this.crp_tech?.items?.[0]?.entity ?? ''
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #crptech-sidebar {
      width: 680px !important;
      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;

        .sidebar-block {
          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }

          a {
            border-bottom: 1px solid #0066ff;
            font-size: 16px;
          }
        }

        .info {
          &__detail {
            a {
              color: #313131;
              font-weight: 600;
              border-bottom: 1px solid #313131;
            }
          }
        }

        .token {
          form {
            margin-top: 7px;

            input {
              height: 42px !important;
              border: 1px solid #bcbcbc;
              border-radius: 2px;
              font-size: 16px;
              color: #313131;
            }
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;

            input,
            .ivu-select {
              width: 282px;
            }
          }
        }
      }
    }
  }

  #crptech-inner {
    #crptech-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }

        .info {
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }
          }
        }

        .token {
          .text {
            margin-top: 16px;

            img {
              margin-left: 5px;
            }
          }
        }

        .b-select {
          &__title {
            .item {
              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }
        }
      }
    }
  }
</style>
