<template>
  <b-sidebar
    id="evotor-sidebar"
    title="Эвотор"
    right
    backdrop
    shadow
    @shown="setIntegration('evotor')"
  >
    <template #header="{ hide }">
      Эвотор
      <img
        class="close-sidebar cursor"
        src="/img/integrations/icon-sidebar-close.svg"
        alt=""
        @click="hide"
      />
    </template>

    <div class="sidebar-block info">
      <p>
        Эвотор – это умные онлайн-кассы, созданные на базе планшета, имеющие дисплей и простой интерфейс управления.
        Эвотор позволяет начать продажи, купив лишь одно устройство вместо целого арсенала оборудования.
      </p>
      <div class="info__detail">
        <img
          src="/img/integrations/icon-info-blue.svg"
          alt=""
        />
        <router-link to="#">Подробная справка</router-link>
        по интеграции
      </div>
    </div>
    <div class="sidebar-block token">
      <div class="text">
        Токен
        <img
          src="/img/integrations/icon-info-gray.svg"
          alt=""
        />
        <b-form @submit.prevent="addToken">
          <b-form-input
            v-model="token"
            type="text"
            placeholder="Номер токена"
          />
        </b-form>
      </div>
      <b-button
        variant="primary"
        class="mt-2"
        :disabled="connect_is_disabled"
        @click="addToken"
      >
        Подключить
      </b-button>
    </div>
    <b-collapse
      id="my-collapse-id"
      v-model="collapse_visible"
    >
      <div class="sidebar-block">
        <div class="block-title">Сопоставьте юрлица с местами хранения</div>
        <div class="b-select">
          <div class="b-select__title">
            <div class="item">Магазин Энтерсайт</div>
            <div class="item">Магазин Эвотор</div>
          </div>
          <div
            v-for="(item, index) in branch_mapping"
            :key="index"
            class="b-select__item"
          >
            <i-select
              v-model="item.int_id"
              filterable
            >
              <i-option
                v-for="(el, index) in branch_list"
                :key="index"
                :value="el.id"
              >
                {{ el.name }}
              </i-option>
            </i-select>
            <img
              src="/img/integrations/arrows-left-right.svg"
              alt=""
              class="cursor"
            />
            <i-select
              v-model="item.ext_id"
              filterable
              @on-change="addExtBranch"
            >
              <i-option
                v-for="(el, index) in evotor_store_list"
                :key="index"
                :value="el.id"
              >
                {{ el.name }}
              </i-option>
            </i-select>
          </div>
        </div>
      </div>
      <div class="sidebar-block">
        <div class="block-title">Сопоставьте роли пользователей</div>
        <div class="b-select">
          <div class="b-select__title">
            <div class="item">Роли пользователей Энтерсайт</div>
            <div class="item">Роли пользователей Эвотор</div>
          </div>
          <div
            v-for="(item, index) in role_mapping"
            :key="index"
            class="b-select__item"
          >
            <i-select
              v-model="item.int_id"
              filterable
            >
              <i-option
                v-for="(el, index) in role_list"
                :key="index"
                :value="el.id"
              >
                {{ el.name }}
              </i-option>
            </i-select>
            <img
              src="/img/integrations/arrows-left-right.svg"
              alt=""
              class="cursor"
            />
            <i-select
              v-model="item.ext_id"
              filterable
            >
              <i-option
                v-for="(el, index) in evotor_role_list"
                :key="index"
                :value="el.id"
              >
                {{ el.name }}
              </i-option>
            </i-select>
          </div>
        </div>
      </div>
      <div class="sidebar-block">
        <b-overlay :show="isIntegrating">
          <div class="block-title">Желаете импортировать товары из кассовой системы Эвотор?</div>
          <div v-if="check_branch_mapping">
            <p>
              Все группы товаров, товары и цены, находящиеся в кассовой системы Эвотор будут импортированы в систему
              Энтерсайт
            </p>
            <a @click="uploadClassif">Импортировать данные</a>
          </div>
          <div v-else>
            <p>Чтобы импортровать данные сопоставьте и сохраните юрлица с местами хранения</p>
          </div>
        </b-overlay>
      </div>
      <div class="sidebar-block">
        <b-overlay :show="isIntegrating">
          <div class="block-title">Желаете экспортировать товары в кассовую систему Эвотор?</div>
          <div v-if="check_branch_mapping">
            <p>
              Все группы товаров, товары и цены, находящиеся в кассовой системе Энтерсайт будут экспортированы в систему
              Эвотор
            </p>
            <a @click="exportClassif">Экспортированы данные</a>
          </div>
          <div v-else>
            <p>Чтобы экспортированы данные сопоставьте и сохраните юрлица с местами хранения</p>
          </div>
        </b-overlay>
      </div>
    </b-collapse>
    <template #footer="{ hide }">
      <div class="b-action-buttons">
        <b-button
          variant="primary"
          class="cursor"
          @click="updateIntegration"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          class="cursor ml-3"
          @click="hide"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import * as R from 'ramda'

  export default {
    apollo: {
      Roles: {
        query: require('../gql/getRoles.gql'),
        result() {},
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },

      CashIntegrations: {
        query: require('../gql/getCashIntegrations.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.integrations = R.pathOr([], ['CashIntegrations'], data)
          this.isLoading = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.isLoading = false
        }
      }
    },

    data: () => ({
      search: '',
      token: '',
      id: '',
      role_list: [],
      branch_list: [],
      role_mapping: [],
      branch_mapping: [],
      evotor_store_list: [],
      evotor_role_list: [],
      integrations: [],
      collapse_visible: false,
      isLoading: true,
      isIntegrating: false,
      check_branch_mapping: false
    }),

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),

      checkIntegration() {
        return {
          evotor: this.integrations.includes((el) => el.type === 'evotor')
        }
      },

      connect_is_disabled() {
        if (this.token.length > 0) return false
        return true
      }
    },

    methods: {
      submitHandlerSearch() {},

      setIntegration(type) {
        const data = this.integrations.find((el) => el.type === type)

        if (!data) return
        this.setSidebarData(data)
        this.collapse_visible = true
      },

      setSidebarData(data) {
        this.token = data.login
        this.id = data.id
        this.role_list = data.int_roles
        this.branch_list = data.int_branches
        this.evotor_role_list = data.ext_roles
        this.evotor_store_list = data.ext_branches
        this.role_mapping = data.role_mapping || [{ int_id: '', ext_id: '' }]
        this.check_branch_mapping = !!data.branch_mapping
        this.branch_mapping = data.branch_mapping
          ? [...data.branch_mapping, {}]
          : [{ int_id: this.currentBranch.id, ext_id: '' }]
      },

      updateIntegration() {
        this.$apollo
          .mutate({
            mutation: require('../gql/UpdateCashIntegration.gql'),
            variables: {
              id: this.id,
              branch_mapping: this.branch_mapping,
              role_mapping: this.role_mapping /* TODO this.role_mapping */
            }
          })
          .then(() => {
            this.$noty.show(`Интеграция успешна`)
            // this.collapse_visible = true
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      addToken() {
        this.$apollo
          .mutate({
            mutation: require('../gql/InitCashIntegration.gql'),
            variables: {
              login: this.token,
              type: 'evotor'
            }
          })
          .then(({ data }) => {
            this.setSidebarData(data.InitCashIntegration)
            this.$noty.show(`Интеграция успешна`)
            this.collapse_visible = true
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      async uploadClassif() {
        try {
          this.isIntegrating = true
          await this.$apollo.mutate({
            mutation: require('../gql/DownloadClassif.gql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                integration: this.id
              }
            }
          })
          this.$noty.show(`Импорт данных успешен`)
        } catch (e) {
          console.error(e)
          this.$noty.show(`При импорте что-то пошло не так. Попробуйте еще раз`)
        } finally {
          this.isIntegrating = false
        }
      },
      async exportClassif() {
        this.$bvModal
          .msgBoxConfirm('При экспорте в все данные в эвоторе будут удалены и записаны данные из энтерсайта', {
            title: 'Вы уверенны?',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Продолжить',
            cancelTitle: 'Отмена',
            cancelVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0 d-flex',
            centered: true
          })
          .then((data) => {
            if (data) this.exportData()
          })
          .catch(() => {
            // An error occurred
          })
      },

      addExtBranch() {
        if (
          this.branch_mapping[this.branch_mapping.length - 1]?.ext_id ||
          this.branch_mapping[this.branch_mapping.length - 1]?.int_id
        )
          this.branch_mapping.push({})
      }
    }
  }
</script>

<style scoped lang="scss">
  #evotor-sidebar {
    width: 680px;

    .b-sidebar-header {
      img.close-sidebar {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 22px;
      }
    }

    .b-sidebar-footer {
      .b-action-buttons {
        padding: 0 22px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .b-sidebar-body {
      .sidebar-block {
        border-bottom: 1px solid #e1e1e1;
        padding: 22px;

        .block-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 16px;
          color: #313131;
          margin-bottom: 16px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }

        a {
          border-bottom: 1px solid #0066ff;
          font-size: 16px;
        }
      }

      .info {
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        &__detail {
          background: #e6f0ff;
          border-radius: 4px;
          margin-top: 22px;
          border-left: 3px solid #b9d6ff;
          padding: 15px;

          img {
            margin-right: 15px;
          }

          a {
            color: #313131;
            font-weight: 600;
            border-bottom: 1px solid #313131;
          }
        }
      }

      .token {
        .text {
          margin-top: 16px;

          img {
            margin-left: 5px;
          }
        }
      }

      .b-select {
        border: 1px solid #e1e1e1;
        border-radius: 4px;

        &__title {
          border-bottom: 1px solid #e1e1e1;
          display: flex;

          .item {
            width: 50%;
            padding: 8px 0;
            text-align: center;

            &:nth-child(2) {
              border-left: 1px solid #e1e1e1;
            }
          }
        }

        &__item {
          display: flex;
          padding: 16px;
          justify-content: space-between;
        }
      }
    }
  }

  ::v-deep() {
    #evotor-sidebar {
      width: 680px;

      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;
      }

      .token {
        form {
          margin-top: 7px;

          input {
            height: 42px !important;
            border: 1px solid #bcbcbc;
            border-radius: 2px;
            font-size: 16px;
            color: #313131;
          }
        }
      }

      .b-select {
        &__item {
          display: flex;
          padding: 16px;
          justify-content: space-between;

          input,
          .ivu-select {
            width: 282px;
          }
        }
      }
    }
  }
</style>
